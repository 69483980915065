import React, { useState } from "react"
import Typist from 'react-typist';
import Layout from "../layout"
import PostalAddress from "../components/address"

const IndexPage = () => {
	const [typingDone, setTypingDone] = useState(false);

	const TypingDone = () => {
		setTypingDone(true);
	}

	const cursorConfig = {
		element: '▋',
		hideWhenDone: true,
		hideWhenDoneDelay: 0
	}

	const cursorConfigEnd = {
    element: '▋'
  }

  return (
    <Layout>
	      <title>Dipl.-Ing. (FH) Tim Isenheim | Full-Stack Web Developer in Hamburg, Germany</title>
	      <div className="prompt prompt__home" id="cat">
	      <Typist startDelay={2000} cursor={cursorConfig} onTypingDone={TypingDone}>cat vcard.md</Typist>
	      </div>

	      <div itemScope itemType="https://schema.org/Person" className={ !typingDone ? 'dn' : 'db' }>
		      <h1>Dipl.-Ing. (FH) <span itemProp="givenName">Tim</span> <span itemProp="familyName">Isenheim</span></h1>
		      <h2><span itemProp="jobTitle">Full-stack web developer</span> from <span itemProp="homeLocation">Hamburg, Germany</span></h2>
		      <hr />

					<section>
			      <p>I offer:</p>

			      <ul>
			        <li itemProp="knowsAbout">Software Engineering</li>
			        <li itemProp="knowsAbout">Full Stack Web Development
			          <ul>
			            <li>I do frontend development</li>
			            <li>I do backend development </li>
			            <li>I handle DevOps</li>
			          </ul>
			        </li>
			      </ul>
		      </section>

	      </div>

				<section className={ !typingDone ? 'dn' : 'db' }>
		      <p>I work here:</p>

		      <PostalAddress />
	      </section>

	      <div className={'prompt prompt__home ' + (!typingDone ? 'dn' : 'db') } id="cat">
        <Typist className={!typingDone ? 'dn' : 'db'} cursor={cursorConfigEnd}></Typist>
        </div>

    </Layout>
  )
}

export default IndexPage
