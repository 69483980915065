import * as React from "react"

const PostalAddress = (props) => {

	let founder = props && props.founder !== null ? <div itemProp="name">{ props.founder }</div> : null;

  return (
    <div className="vcard" itemScope itemType="https://schema.org/Organization">
      <div itemProp="name">freshlabs GbR</div>
      {founder}
      <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
        <div className="streetAddress">Steindamm 27</div>
        <span className="postalCode">20099</span>&nbsp;
        <span className="addressLocality">Hamburg, Germany</span>
      </div>
      <div itemProp="telephone"><a href="tel:+4940881416660">+49 (0)40 88 141 66 60</a></div>
      <div itemProp="url" className="url"><a href="https://www.freshlabs.de">www.freshlabs.de</a></div>
    </div>
  )
}

export default PostalAddress